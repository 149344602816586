<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header map">
            <div class="icon"></div>
            <span class="fL">優惠地圖</span>
            <NotificationBell
                @count-unread="countUnread = $event"
            />
        </section>
        <!-- Main -->
        <section class="main map">
            <!-- Tabs -->
            <div class="tabs line fM rounded">
                <!-- <a class="tab" @click.prevent="">
                    <span class="fM">優惠券</span>
                </a> -->
                <!-- <div class="lineV"></div> -->
                <a class="tab" @click.prevent="$router.push('/stores')">
                    <span class="fM">特店列表</span>
                </a>
                <div class="lineV"></div>
                <a class="tab active" @click.prevent="$router.push('/map')">
                    <span class="fM">特店地圖</span>
                </a>
            </div>

            <!-- Filter -->
            <div class="filter">
                <div class="flexH">
                    <!-- <div class="flexH middle">
                        <div class="dropdown rounded">
                            <select name="type" class="fM rounded">
                                <option value="分類">分類</option>
                                <option value="分類">分類</option>
                            </select>
                            <div class="arrow third">
                                <font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon>
                            </div>
                        </div>
                    </div>
                    <div class="lineV"></div> -->
                    <div class="flexH middle">
                        <div class="dropdown rounded">
                            <select name="type" class="fM rounded" v-model="filterRegion" @change="filterHandler">
                            <option value="-1">地區</option>
                            <option v-for="(region) in regions" :value="region.id" :key="region.id">{{region.name}}</option>
                        </select>
                            <div class="arrow third">
                                <font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--vvvvvvvvvv REMOVE TEST IMAGE vvvvvvvvvv-->
            <div class="img testImg">
                <!-- <div class="search_location">
                        <input
                            type="text"
                            :placeholder="lang.curlang[0]"
                            :value="address"
                        />

                        <button
                            :disabled="dialog"
                            :loading="dialog"
                            @click="onLocationClick"
                        >
                            我的位置
                        </button>
                    </div> -->
                <devicemap ref="wifimap" class="wifimapcss" @store-click="onStoreClick($event)"
                    @map-click="onMapClick($event)"
                    @regions="updateRegionsHandler"
                    :filterRegion="filterRegionObj"
                />

                <!-- <img src="@/assets/img/map.png"> -->
            </div>
            <!--^^^^^^^^^^ REMOVE TEST IMAGE ^^^^^^^^^^-->
            <!-- 搜尋 -->
            <!-- <div class="input rounded">
                <input type="text" class="fM" placeholder="地址搜尋" value="">
                <a class="icon iconButton">
                    <font-awesome-icon icon="fas fa-arrow-right" size="lg"></font-awesome-icon>
                </a>
            </div> -->
            <div class="bottom flexV end">
                <!-- 定位 -->
                <a class="location icon iconButton white" @click.prevent="onLocationClick">
                    <font-awesome-icon icon="fa-solid fa-location-crosshairs" size="xl"></font-awesome-icon>
                </a>
                <!-- 特店資訊 -->
                <a class="information flexH width between" @click.prevent="$router.push(`/stores/${storeId}/info`)" v-show="!isHideStoreInfo">
                    <!-- class: hide -->
                    <div class="flexH">
                        <img class="store" src="@/assets/img/store.png">
                        <div class="flexV">
                            <span class="fL">{{ displayStoreName }}</span>
                            <span class="fM gray">{{
                                    displayStoreAddress
                            }}</span>
                            <div class="detail flexH">
                                <div class="img point"><img src="@/assets/icon/point.svg"></div>
                                <span class="fS gray">紅利桃子</span>
                                <!-- <span class="fS gray">・</span>
                                <div class="img ticket"><img src="@/assets/icon/ticket.svg"></div>
                                <span class="fS gray">優惠券</span>
                                <span class="fS gray">・</span>
                                <div class="img stamp"><img src="@/assets/icon/stamp.svg"></div>
                                <span class="fS gray">集章</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="icon iconButton third"><i class="fa-solid fa-chevron-right fa-lg"></i></div>
                </a>
            </div>
        </section>
        <!-- Footer -->
        <section class="footer">
            <a class="icon iconButton" href="index.html">
                <img src="@/assets/icon/home.svg" />
                <img src="@/assets/icon/home-a.svg" />
            </a>
            <a class="icon iconButton disabled">
                <img src="@/assets/icon/service.svg" />
                <img src="@/assets/icon/service-a.svg" />
            </a>
            <a class="icon iconButton" href="scan.html">
                <img src="@/assets/icon/scan.svg" />
                <img src="@/assets/icon/scan-a.svg" />
            </a>
            <a class="icon iconButton active">
                <img src="@/assets/icon/store.svg" />
                <img src="@/assets/icon/store-a.svg" />
            </a>
            <a class="icon iconButton" href="profile.html">
                <img src="@/assets/icon/user.svg" />
                <img src="@/assets/icon/user-a.svg" />
            </a>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import devicemap from "@/components/DeviceMap.vue";
import { mapState } from "vuex";
import axios from 'axios';
import NotificationBell from "@/components/NotificationBell.vue";

export default {
    components: {
        devicemap,
        NotificationBell
    },
    data() {
        return {
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            address: "",
            loading: true,
            dialog: false,
            isLangEn: false,
            lang: {
                curlang: [],
                en: ["Turn on positioning permission", "Locating your address"],
                zhtw: [
                    "開啟定位查詢您的位置,點擊右方按鈕定位所在位置",
                    "正在定位您的所在位置"
                ],
                zhcn: [
                    "开启定位查询您的位置,点击右方按钮定位所在位置",
                    "正在定位您的所在位置"
                ],
                jp: [
                    "位置情報サービスをオンにして、現在地を検索してください。右側のボタンを押すと現在地を特定できます",
                    "現在地の特定"
                ],
                kr: [
                    "위치 검색을 열고 오른쪽 버튼을 클릭하여 위치를 찾습니다",
                    "위치 찾기 시작"
                ]
            },
            storeInfo: {
                id: null,
                name: "",
                address: "",
                phone: "",
                info: "",
                reset: function () {
                    this.id = null;
                    this.name = "";
                    this.address = "";
                    this.phone = "";
                    this.info = "";
                }
            },
            isHideStoreInfo: true,
            isNotificationBar: false,
            notificationBarContent: {
                title: "",
                message: "",
                time: "",
                points: "",
                reset: function () {
                    this.title = "";
                    this.message = "";
                    this.time = "";
                    this.points = "";
                }
            },
            firstNotification: {},
            countUnread: 0,
            regions: [],
            regionMap: {},
            filterRegion: -1,
            filterRegionObj: {}
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user"]),
        displayStoreName() {
            return this.storeInfo.name;
        },
        displayStoreAddress() {
            return this.storeInfo.address;
        },
        storeId() {
            console.log("this.storeInfo: ", this.storeInfo)
            return this.storeInfo.id;
        }
    },
    mounted() {
        if (this.$route.query.lang == "en") {
            this.lang.curlang = this.lang.en;
        } else if (this.$route.query.lang == "kr") {
            this.lang.curlang = this.lang.kr;
        } else if (this.$route.query.lang == "jp") {
            this.lang.curlang = this.lang.jp;
        } else if (this.$route.query.lang == "zh-cn") {
            this.lang.curlang = this.lang.zhcn;
        } else {
            this.lang.curlang = this.lang.zhtw;
        }
        this.onLocationClick();
        this.getZones();
    },
    methods: {
        onMapClick() {
            this.isHideStoreInfo = true;
            this.storeInfo.reset();
        },
        onStoreClick(e) {
            Object.assign(this.storeInfo, e);
            this.isHideStoreInfo = false;
        },
        onLocationClick() {
            var that = this;
            if (navigator.geolocation) {
                that.dialog = true;

                let currentObj = this;

                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        var pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        let rGeoAPI =
                            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                            pos.lat +
                            "," +
                            pos.lng +
                            "&key=AIzaSyDSnn4PLwW7kMPBmXkOrcLXrA4Gd-31VRU";
                        if (currentObj.$route.query.lang == "en") {
                            rGeoAPI += "&language=en";
                        }
                        axios
                            .get(rGeoAPI)
                            .then(function (response) {
                                currentObj.address =
                                    response.data.results[0].formatted_address;
                                that.dialog = false;

                                that.$refs.wifimap.initializeUserMarker(pos);
                            })
                            .catch(function (error) {
                                that.dialog = false;
                                console.log(error);
                            });
                    },
                    function (error) {
                        console.log("getCurrentPosition failure", error);
                        that.dialog = false;
                        that.lang.curlang[0] = error.message;
                    }
                );
            } else {
                console.log("no navigator.geolocation");
                that.lang.curlang[0] = "no novagator.geolocation";
                that.dialog = false;
            }
        },
        getZones() {
            let config = {
                url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/portals/tyapp/zones`,
                method: "get"
            };
            console.log('query zones ', config);

            return this.$http(config)
            .then((response) => {
                console.log("zone response: ", response.data);
                this.regions = response.data;
                let regionMap = {};
                for (let r of this.regions) {
                    regionMap[r.id] = r;
                }
                this.regionMap = regionMap;
            });
            
        },
        updateRegionsHandler(e) {
            console.log("e: ", e)
            this.regions = e;
            let regionMap = {};
            for (let r of this.regions) {
                regionMap[r.id.toString()] = r;
            }
            this.regionMap = regionMap;
        },
        filterHandler() { // Device Map似乎有listen
            // console.log("this.filterRegion: ", this.filterRegion)
            // console.log('the obj check ', this.regionMap[this.filterRegion.toString()]);
            this.filterRegionObj = this.regionMap[this.filterRegion.toString()];
        }
    }
};
</script>

<style scoped>
.wifimapcss {
    height: 500px;
}
</style>
